import { combineReducers } from 'redux';
import { dashboard } from './dashboard'
import { users } from './users'
import { site } from './site'

const rootReducer = combineReducers({
  dashboard,
  users,
  site
});

export default rootReducer;