import { CONSTANTS } from '../api';
let initState = {
    data: [],
    latest: {}
}

export function site(state = initState, action) {
    //console.log(action)
    let __state = { ...state }
    switch (action.type) {
        case CONSTANTS.SITE.SUCCESS:
            return {
                data: action.result
            };
        case CONSTANTS.SITE.LATEST_DATA_SUCCESS:
            console.log('reducer site', action.data)
                __state = {...state}
                __state.data = action.data
            return __state
        case CONSTANTS.SITE.LATEST_COMPANY_DATA_SUCCESS:
            console.log('reducer site', action.data)
                __state = {...state}
                __state.data = action.data
            return __state
        case CONSTANTS.CLEAR:
            return initState
        default:
            return state
    }
}

